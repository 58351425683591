import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import Select from '@/dc-it/types/Select'
import helpers from '@/dc-it/services/helpers'
import Relation from '@/dc-it/types/Relation'
import User from '@/dc-it/models/User'

export default class CycleMovement extends Model {
    endpoint = 'cycle_movements';

    related = ['user'];

    types = {
      1: 'Ingreso',
      2: 'Egreso',
    }

    fields = [
      new Field('id', 'Código').disabled(),
      new Relation('user', 'Usuario', User, 'email'),
      new Field('date', 'Fecha', 'date'),
      new Field('description', 'Descripción'),
      new Field('amount', 'Monto', 'number').applyMask(val => {
        let result = `$ ${helpers.numberFormat(val)}`
        const date = new Date(`${this.getAttr('date')}T00:00:00`)
        if (date > new Date()) {
          result += '<span class="badge badge-warning">Pendiente</span>'
        } else {
          result += '<span class="badge badge-success">Aplicado</span>'
        }
        return result
      }),
      new Select('type', 'Tipo', this.types),

    ]

    clone = () => CycleMovement;
}
