<template>
  <div>
    <table-component
      :model="model"
      :permissions="permissions"
    />
  </div>
</template>

<script>
import TableComponent from '@/dc-it/components/TableComponent.vue'
import CycleMovement from '@/dc-it/models/CycleMovement'

export default {
  name: 'CyleMovementsView',
  components: { TableComponent },
  data() {
    return {
      model: CycleMovement,
      permissions: {
        create: true,
        update: false,
        delete: false,
        filter: true,
      },
    }
  },
}
</script>

<style scoped>

</style>
