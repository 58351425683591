import { render, staticRenderFns } from "./CycleMovementsView.vue?vue&type=template&id=1b25c824&scoped=true&"
import script from "./CycleMovementsView.vue?vue&type=script&lang=js&"
export * from "./CycleMovementsView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b25c824",
  null
  
)

export default component.exports